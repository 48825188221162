/*
 * Image replacement
 */
/*
* Clearfix: contain floats
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
*    `contenteditable` attribute is included anywhere else in the document.
*    Otherwise it causes space to appear at the top and bottom of elements
*    that receive the `cf` class.
* 2. The use of `table` rather than `block` is only necessary if using
*    `:before` to contain the top-margins of child elements.
*/
main section .section-wrapper form {
  text-align: left;
}
main section .section-wrapper form p.req {
  font-size: 0.875rem;
}
main section .section-wrapper form input[type=email],
main section .section-wrapper form input[type=text],
main section .section-wrapper form select {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  margin: 0 auto;
  padding: 0 1rem;
  font-size: 1rem;
  border: 1px solid #c4c4c4;
  outline: none;
  background-color: #ffffff;
}
main section .section-wrapper form input[type=email].error,
main section .section-wrapper form input[type=text].error,
main section .section-wrapper form select.error {
  border-color: #ff0000;
}
main section .section-wrapper form input[type=email],
main section .section-wrapper form input[type=text] {
  color: #435770;
}
main section .section-wrapper form input[type=email]::placeholder,
main section .section-wrapper form input[type=text]::placeholder {
  color: #b0b0b0;
}
main section .section-wrapper form input[type=email]:focus,
main section .section-wrapper form input[type=text]:focus {
  color: #435770;
  border-color: #63C6BD;
}
main section .section-wrapper form input[type=email]:focus::placeholder,
main section .section-wrapper form input[type=text]:focus::placeholder {
  color: #435770;
}
main section .section-wrapper form select {
  color: #b0b0b0;
  appearance: none;
}
main section .section-wrapper form select option[value=""] {
  color: #b0b0b0;
}
main section .section-wrapper form select.hasValue {
  color: #435770;
}
main section .section-wrapper form select:focus {
  border-color: #63C6BD;
  color: #435770;
}
main section .section-wrapper form button {
  display: inline-block;
  width: auto;
  height: auto;
  border-radius: 100px;
  padding: 10px 1em;
  font-size: 1rem;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: #435770;
  border: 2px solid #435770;
}
main section .section-wrapper form button:hover {
  color: #435770;
  background-color: #E0EFEE;
}
main section .section-wrapper form button.white {
  color: #435770;
  background-color: #ffffff;
}
main section .section-wrapper form button.white:hover {
  color: #ffffff;
  background-color: #435770;
}
main section .section-wrapper form button.loading:disabled {
  position: relative;
  padding-right: calc(40px + 1em);
  background-color: #dddddd;
  border-color: #cccccc;
  color: #888888;
  cursor: wait;
}
main section .section-wrapper form button.loading:disabled:after {
  content: "";
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-color: #435770;
  border-radius: 10px;
  background-color: transparent;
  animation-name: kfs_loading;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
main section .section-wrapper form label.checkbox {
  position: relative;
  display: block;
  margin: 0.5em 0 0 0;
  padding: 0 0 0 30px;
}
main section .section-wrapper form label.checkbox input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
main section .section-wrapper form label.checkbox input + span.text:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: -0.25em;
  border: 3px solid #ffffff;
  background: #ffffff;
  box-shadow: 0 0 0 1px #c4c4c4;
  margin-right: 0.75em;
}
.b_firefox main section .section-wrapper form label.checkbox input + span.text:before {
  box-shadow: 0 0 1px 1px #c4c4c4;
}
main section .section-wrapper form label.checkbox input:checked + span.text:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 1px;
  width: 8px;
  height: 15px;
  border: solid #63C6BD;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
main section .section-wrapper form label.checkbox input:focus + span.text::before {
  box-shadow: 0 0 0 1px #63C6BD !important;
}
.b_firefox main section .section-wrapper form label.checkbox input:focus + span.text::before {
  box-shadow: 0 0 1px 1px #63C6BD !important;
}
main section .section-wrapper form label.checkbox input.error + span.text::before {
  box-shadow: 0 0 0 1px #ff0000;
}
.b_firefox main section .section-wrapper form label.checkbox input.error + span.text::before {
  box-shadow: 0 0 1px 1px #ff0000;
}
main section .section-wrapper form label.radio {
  position: relative;
  display: block;
  margin: 0.5em 2rem 0 0;
  padding: 0 0 0 30px;
}
main section .section-wrapper form label.radio:last-child {
  margin-right: 0;
}
main section .section-wrapper form label.radio input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
main section .section-wrapper form label.radio input + span.text:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: -0.25em;
  border-radius: 10px;
  border: 3px solid #ffffff;
  background: #ffffff;
  box-shadow: 0 0 0 1px #c4c4c4;
  margin-right: 0.75em;
}
.b_firefox main section .section-wrapper form label.radio input + span.text:before {
  box-shadow: 0 0 1px 1px #c4c4c4;
}
main section .section-wrapper form label.radio input:checked + span.text::before {
  background: #02cfde !important;
}
main section .section-wrapper form label.radio input:focus + span.text::before {
  box-shadow: 0 0 0 1px #02cfde !important;
}
.b_firefox main section .section-wrapper form label.radio input:focus + span.text::before {
  box-shadow: 0 0 1px 1px #02cfde !important;
}
main section .section-wrapper form label.radio input.error + span.text::before {
  box-shadow: 0 0 0 1px #ff0000;
}
.b_firefox main section .section-wrapper form label.radio input.error + span.text::before {
  box-shadow: 0 0 1px 1px #ff0000;
}
main section .section-wrapper form .fields {
  display: block;
  margin: 1rem auto;
}
main section .section-wrapper form .fields h3 {
  margin: 2rem 0 0.5rem 0;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1em;
}
main section .section-wrapper form .fields fieldset {
  position: relative;
  display: block;
}
main section .section-wrapper form .fields fieldset label.h3 {
  display: block;
  margin: 1rem 0 0.5rem 0;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: 1em;
}
main section .section-wrapper form .fields fieldset .errorMsg {
  color: #CC0000 !important;
  margin-top: 0.5rem !important;
}
main section .section-wrapper form .fields fieldset .errorMsg.hidden {
  display: none;
}
main section .section-wrapper form .fields fieldset p,
main section .section-wrapper form .fields fieldset .errorMsg {
  padding: 0 1rem;
}
main section .section-wrapper form .fields.two fieldset,
main section .section-wrapper form .fields .cols fieldset {
  margin: 1rem auto;
}
main section .section-wrapper form .fields.radio-list {
  margin: 2rem auto 1rem 0;
}
main section .section-wrapper form .fields.radio-list fieldset legend {
  font-weight: 700;
}
main section .section-wrapper form .fields.radio-list fieldset label {
  margin: 1rem auto;
}
main section .section-wrapper form .recaptcha {
  width: 258px;
  height: 62px;
  margin: 2rem auto;
  border: 1px solid #c4c4c4;
}
.evo_screenshooting main section .section-wrapper form .recaptcha {
  background-image: url("/assets/img/screenshot-recaptcha.ba5c6b58.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.evo_screenshooting main section .section-wrapper form .recaptcha > div,
.evo_screenshooting main section .section-wrapper form .recaptcha > iframe {
  display: none !important;
}
main section .section-wrapper form .recaptcha > div {
  box-shadow: none !important;
}
main section .section-wrapper form .fieldActions {
  margin: 2rem auto 0 auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
  main section .section-wrapper h1, main section .section-wrapper p:not(.req) {
    text-align: center;
  }
}

@media only screen and (min-width: 1170px) {
  main section .section-wrapper form button {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  main section .section-wrapper form .fields h3 {
    margin: 1rem 0 0.5rem 0;
  }
  main section .section-wrapper form .fields.two {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  main section .section-wrapper form .fields.two fieldset {
    width: 50%;
    margin: 0 0.5rem;
  }
  main section .section-wrapper form .fields.two fieldset:first-child {
    margin-left: 0;
  }
  main section .section-wrapper form .fields.two fieldset:last-child {
    margin-right: 0;
  }
  main section .section-wrapper form .fields.two.address fieldset:first-child {
    width: 75%;
  }
  main section .section-wrapper form .fields.two.address fieldset:last-child {
    width: 25%;
  }
  main section .section-wrapper form .fields.radio-list {
    width: 75%;
  }
  main section .section-wrapper form .fields .cols {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  main section .section-wrapper form .fields .cols fieldset {
    margin: 0 0.5rem 1rem 0.5rem;
  }
  main section .section-wrapper form .fields .cols fieldset:first-child {
    margin-left: 0;
  }
  main section .section-wrapper form .fields .cols fieldset:last-child {
    margin-right: 0;
  }
  main section .section-wrapper form .fields .cols.two fieldset {
    width: 50%;
  }
  main section .section-wrapper form .fields .cols.two.address fieldset:first-child {
    width: 80%;
  }
  main section .section-wrapper form .fields .cols.two.address fieldset:last-child {
    width: 20%;
  }
  main section .section-wrapper form .fields .cols.three fieldset {
    width: 33.333%;
  }
  main section .section-wrapper form .fields .cols.three.csz fieldset {
    width: 40%;
  }
  main section .section-wrapper form .fields .cols.three.csz fieldset:last-child {
    width: 20%;
  }
  main section .section-wrapper form .recaptcha {
    margin-left: 30px;
  }
  main section .section-wrapper form .fieldActions {
    margin-left: 30px;
    text-align: left;
  }
}